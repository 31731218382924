import React from "react";
import "./Pagethird.scss";
import yenwiseLogo from "../../assets/yenwise_logo.svg";
import footerimg from "../../assets/footer-very-fast.mp4";

import instagram from "../../assets/instagram.png";
import linkedin from "../../assets/linkedin.png";

const Pagethird = () => {
  return (
    <div className="main-container-third">
      <video className="background-video" muted loop autoPlay playsInline>
        <source src={footerimg} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <div className="content-third">
        <img src={yenwiseLogo} alt="Yenwise Logo" className="logo" />
        <button className="button">LET'S TALK</button>
      </div>
      <footer className="footer">
        <div className="footer-item">info@yenwise.com</div>
        <div className="footer-item">
          <a
            href="https://www.instagram.com/yenwisetech/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={instagram} alt="Instagram" width={25} />
          </a>
        </div>
        <div className="footer-item">
          <a
            href="https://www.linkedin.com/company/yenwisetech/about/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={linkedin} alt="LinkedIn" width={25} />
          </a>
        </div>
      </footer>
    </div>
  );
};

export default Pagethird;
