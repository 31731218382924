import React, { useEffect } from "react";
import yenwiseLogo from "../../assets/yenwise_logo.svg"; 
import waveVideo from "../../assets/wave-fast.mp4";
import "./Introductionscreen.scss"; 
import { FaArrowRight } from "react-icons/fa"; 

const Introductionscreen = () => {
  useEffect(() => {
    const headers = document.querySelectorAll(".typing");
    let currentHeaderIndex = 0; // Start with the first header

    const cycleHeaders = () => {
      const currentHeader = headers[currentHeaderIndex];
      currentHeader.style.display = "block"; // Show the current header

      // Start typing animation
      currentHeader.style.animation =
        "typing 3.5s steps(40, end), blink-caret .75s step-end infinite";

      setTimeout(() => {
        // Start deleting animation
        currentHeader.style.animation =
          "deleting 3.5s steps(40, end), blink-caret .75s step-end infinite";

        setTimeout(() => {
          currentHeader.style.display = "none"; // Hide the header after animation completes

          // Move to the next header, or loop back to the first
          currentHeaderIndex = (currentHeaderIndex + 1) % headers.length;
          cycleHeaders(); // Continue with the next header
        }, 3500); // Timing for the deleting animation
      }, 3500); // Timing for the typing animation
    };

    cycleHeaders(); // Start the cycle immediately
  }, []);
  
  return (
    <div className="home-page">
      <header className="header">
        <img src={yenwiseLogo} alt="Yenwise Logo" className="logo" />
      </header>

      <video className="background-video" muted loop autoPlay playsInline>
        <source src={waveVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <div className="main-content">
        <h1>Delivering Technology-as-a-Service.</h1>
        <h2 className="typing typing-1">Unleashing the Power of Blockchain.</h2>
        <h2 className="typing typing-2">Innovative Web3 Solutions.</h2>
        <h2 className="typing typing-3">Future of Digital Finance.</h2>
        <h2 className="typing typing-4">Secure and Scalable Technology.</h2>
      </div>

      <div className="footer-links">
        <div className="links-container">
          <div className="link link1">
            <span className="default-text">Leveraging Blockchain for Enhanced Security</span>
            <span className="hover-text">Explore how our advanced blockchain solutions enhance the security and transparency of your digital transactions.</span>
            <FaArrowRight />
          </div>
          <div className="link link2">
            <span className="default-text">Web3 Innovations for the Modern Business</span>
            <span className="hover-text">Discover the transformative potential of our web3 technologies in revolutionizing your business operations.</span>
            <FaArrowRight />
          </div>
          <div className="link link3">
            <span className="default-text">Empowering Digital Economies with Scalability</span>
            <span className="hover-text">Learn how our scalable solutions are redefining the landscape of the digital economy for businesses and individuals.</span>
            <FaArrowRight />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Introductionscreen;
