import React from "react";
import "./App.css";

import Introductionscreen from "./screens/introductionscreen/Introductionscreen";
import Pagesecond from "./screens/pagesecond/Pagesecond";
import Pagethird from "./screens/pagethird/Pagethird";

function App() {
  return (
    <div>
      <section className="introduction-section section">
        <Introductionscreen />
      </section>
      <section className="page-second-section section">
        <div className="background">
          <div className="circle purple"></div>
          <div className="circle green"></div>
          <div className="circle blue"></div>
          <div className="circle purple"></div>
          <div className="circle green"></div>
          <div className="circle blue"></div>
        </div>
        <Pagesecond />
        <Pagethird />
        <div className="background">
          <div className="circle purple"></div>
          <div className="circle green"></div>
          <div className="circle blue"></div>
        </div>
      </section>
    </div>
  );
}

export default App;
