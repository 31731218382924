import React, { useState } from "react";
import "./Pagesecond.scss";

import feementVideo from "../../assets/3129957-uhd_3840_2160_25fps.mp4";
import vanguardlinkVideo from "../../assets/4389377-uhd_3840_2024_30fps.mp4";

const Pagesecond = () => {
  const [activeLink, setActiveLink] = useState("Feement");

  const content = {
    Feement: {
      title: "What is Feement?",
      text: [
        "Feement is an advanced blockchain platform leveraging the Tron infrastructure to offer comprehensive wallet and private key services. Users can generate multiple wallets, transfer cryptocurrencies between them, and conduct peer-to-peer (P2P) transactions.",
        "Feement's ultimate goal is to support internal cryptocurrency exchanges, providing a scalable and secure system for managing digital assets.",
        "This step-by-step evolving platform ensures compliance with local regulations while delivering cutting-edge solutions for digital finance.",
      ],
      video: vanguardlinkVideo,
      link: "https://www.feement.com"

    },
    Vanguardlink: {
      title: "Vanguardlink",
      text: [
        "Vanguardlink is a cutting-edge integration platform by Yenwise Technology that simplifies the connection of various applications and services through a single API.",
        "It ensures seamless data exchange, automates workflows, and enhances operational efficiency.",
        "Designed to scale with your business, Vanguardlink provides secure and compliant integrations, making it easier to manage your digital ecosystem.",
      ],
      video: feementVideo,
      link: "https://www.vanguardlink.com"

    },
  };

  return (
    <div className="main-container">
      <div className="content">
        <h1>This is yenwise.</h1>
      </div>
      <nav className="navigation">
        <a
          href="#Feement"
          className={activeLink === "Feement" ? "active" : ""}
          onClick={() => setActiveLink("Feement")}
        >
          What is Feement?
        </a>
        <a
          href="#Vanguardlink"
          className={activeLink === "Vanguardlink" ? "active" : ""}
          onClick={() => setActiveLink("Vanguardlink")}
        >
          Vanguardlink
        </a>
      </nav>
      <section className="content">
        <div style={{border: "3px dashed rgba(240,0,125,255)", borderRadius:'200px', padding: '10px'}}>
          <div style={{border: "3px solid white", borderRadius:'200px', backgroundColor:'black'}}>
          <video
            key={activeLink}
            className="hologram-image"
            muted
            loop
            autoPlay
            playsInline
          >
            <source src={content[activeLink].video} type="video/mp4"/>
            Your browser does not support the video tag.
          </video>
          </div>
         
        </div>

        <div className="text-content">
          <h2>{content[activeLink].title}</h2>
          {content[activeLink].text.map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
          ))}
          <a href={content[activeLink].link} className="styled-button" target="_blank" rel="noopener noreferrer">
            <span className="button-text">To {activeLink}</span>
            <span className="button-icon">&#x279C;</span>
          </a>
        </div>
      </section>
    </div>
  );
};

export default Pagesecond;
